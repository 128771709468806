import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { GDRP_LABEL } from '../shared/gdpr/gdpr-label';
import { LegacyAccountService } from './legacy-account.service';

export interface AccountUpdateData {
  fullname?: string;
  lawyerInfos?: {
    areaOfExpertiseIds?: number[];
    oathDate?: Date | null;
    bar?: string | null;
    office?: {
      address?: string | null;
      city?: string | null;
      zipCode?: string | null;
      phone?: string | null;
      fax?: string | null;
      email?: string | null;
    };
    website?: string | null;
    facebook?: string | null;
    twitter?: string | null;
    linkedIn?: string | null;
    gdprAccepted?: boolean;
  };
}

export interface ChangePasswordData {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface UpdateAccountNewsletterConfigData {
  newsCategoryIds: number[];
  days: number[];
  hour: number;
}

@Injectable({
  providedIn: 'root',
  useExisting: LegacyAccountService,
})
export class AccountService {
  private readonly http = inject(HttpClient);
  private readonly gdprLabel = inject(GDRP_LABEL);

  private readonly updatedSubject = new Subject<void>();
  readonly updated = this.updatedSubject.asObservable();

  update(data: AccountUpdateData): Observable<void> {
    return this.http
      .post<void>(`${environment.apiUrl}/user/profile`, {
        nom: data.fullname,
        lawyer_details: data.lawyerInfos && {
          username: data.fullname,
          lawyersSkills: data.lawyerInfos.areaOfExpertiseIds,
          oathDate: data.lawyerInfos.oathDate?.toISOString(),
          officeAddress: data.lawyerInfos.office?.address,
          officeCity: data.lawyerInfos.office?.city,
          bar: data.lawyerInfos.bar,
          officePostcode: data.lawyerInfos.office?.zipCode,
          officePhone: data.lawyerInfos.office?.phone,
          officeFax: data.lawyerInfos.office?.fax,
          officeEmail: data.lawyerInfos.office?.email,
          gdpr: data.lawyerInfos.gdprAccepted,
          gdprLabel: data.lawyerInfos.gdprAccepted ? this.gdprLabel : null,
          website: data.lawyerInfos.website,
          facebook: data.lawyerInfos.facebook,
          twitter: data.lawyerInfos.twitter,
          linkedIn: data.lawyerInfos.linkedIn,
        },
      })
      .pipe(tap(() => this.updatedSubject.next()));
  }

  changePassword(data: ChangePasswordData): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/user/password`, {
      oldPass: data.currentPassword,
      newPass: data.newPassword,
      newPassConfirm: data.confirmNewPassword,
    });
  }

  updateNewsletterConfig(
    data: UpdateAccountNewsletterConfigData,
  ): Observable<void> {
    return this.http
      .post<void>(`${environment.apiUrl}/user/profile`, {
        newsletter_config: {
          topics_selected: Object.fromEntries(
            data.newsCategoryIds.map((id) => [id, id.toString()]),
          ),
          days: Object.fromEntries(
            data.days.map((day) => [day, day.toString()]),
          ),
          hour: data.hour,
        },
      })
      .pipe(tap(() => this.updatedSubject.next()));
  }
}
