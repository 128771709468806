import { LawyerAreaOfExpertise } from '../shared/lawyers/lawyer-area-of-expertise';
import { UserPermission } from './user-permissions';

export type UserStatus =
  | 'PAID'
  | 'TRIAL'
  | 'PENDING'
  | 'GRACEPERIOD'
  | 'EXPIRED';

export const ACTIVE_STATUSES: UserStatus[] = ['PAID', 'TRIAL', 'GRACEPERIOD'];

export interface User {
  id: number;
  fullname: string;
  email: string;
  phone: string | null;
  newsletterConfig: UserNewsletterConfig;
  verified: boolean;
  expireAt: string;
  lawyerInfos?: LaywerUserInfos;
  permissions: UserPermission[];
  status: UserStatus;
  category: string;
}

export interface UserNewsletterConfig {
  days: number[];
  newsCategoryIds: number[];
  hour: number;
}

export interface UserGdprInfos {
  accepted: boolean;
  label: string;
}

export interface LaywerOffice {
  email: string | null;
  fax: string | null;
  phone: string | null;
  zipCode: string | null;
  city: string | null;
  address: string | null;
}

export interface LaywerUserInfos {
  office: LaywerOffice;
  oathDate: string | null;
  areasOfExpertise: LawyerAreaOfExpertise[];
  gdpr: UserGdprInfos;
  twitter: string | null;
  facebook: string | null;
  linkedIn: string | null;
  website: string | null;
  bar: string | null;
}
