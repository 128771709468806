import { formatDate } from '@angular/common';
import { InjectionToken, LOCALE_ID, inject } from '@angular/core';

export const GDRP_LABEL = new InjectionToken<string>(
  'Label printed next to theGDPR checkbox acceptance',
);

export function gdrpLabelFactory(): string {
  return `En date du ${formatDate(
    new Date(),
    'longDate',
    inject(LOCALE_ID),
  )}, j'autorise Juri'predis à utiliser les informations saisies dans le but suivant: publication sur juripredis.com`;
}
