import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject, of, switchMap, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiError } from '../shared/api/interfaces/api-error';
import { GDRP_LABEL } from '../shared/gdpr/gdpr-label';
import { ApiErrorSnackBarComponent } from '../shared/snack-bars/api-error-snack-bar/api-error-snack-bar.component';
import {
  AccountUpdateData,
  ChangePasswordData,
  UpdateAccountNewsletterConfigData,
} from './account.service';

export interface LegacyChangePasswordSuccessResult {
  isSuccess: true;
}
export interface LegacyChangePasswordErrorResult {
  isSuccess: false;
  message: string;
}
export type LegacyChangePasswordResult =
  | LegacyChangePasswordSuccessResult
  | LegacyChangePasswordErrorResult;

@Injectable({
  providedIn: 'root',
})
export class LegacyAccountService {
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);
  private readonly gdprLabel = inject(GDRP_LABEL);

  private readonly updatedSubject = new Subject<void>();
  readonly updated = this.updatedSubject.asObservable();

  update(data: AccountUpdateData): Observable<void> {
    return this.http
      .post<void>(`${environment.apiUrl}/user/profile`, {
        nom: data.fullname,
        lawyer_details: data.lawyerInfos && {
          username: data.fullname,
          lawyersSkills: data.lawyerInfos.areaOfExpertiseIds,
          oathDate: data.lawyerInfos.oathDate?.toISOString(),
          officeAddress: data.lawyerInfos.office?.address,
          officeCity: data.lawyerInfos.office?.city,
          bar: data.lawyerInfos.bar,
          officePostcode: data.lawyerInfos.office?.zipCode,
          officePhone: data.lawyerInfos.office?.phone,
          officeFax: data.lawyerInfos.office?.fax,
          officeEmail: data.lawyerInfos.office?.email,
          gdpr: data.lawyerInfos.gdprAccepted,
          gdprLabel: data.lawyerInfos.gdprAccepted ? this.gdprLabel : null,
          website: data.lawyerInfos.website,
          facebook: data.lawyerInfos.facebook,
          twitter: data.lawyerInfos.twitter,
          linkedIn: data.lawyerInfos.linkedIn,
        },
      })
      .pipe(tap(() => this.updatedSubject.next()));
  }

  changePassword(data: ChangePasswordData): Observable<void> {
    return this.http
      .post<LegacyChangePasswordResult>(`${environment.apiUrl}/user/password`, {
        oldPass: data.currentPassword,
        newPass: data.newPassword,
        newPassConfirm: data.confirmNewPassword,
      })
      .pipe(
        switchMap((result) =>
          result.isSuccess
            ? of(undefined)
            : throwError(() => ({
                code: result.message.toUpperCase(),
                description: result.message.replace('_', ' '),
              })),
        ),
        tap({
          error: (error: ApiError) => {
            this.snackBar.openFromComponent(ApiErrorSnackBarComponent, {
              data: error,
              duration: 6000,
              panelClass: 'jrp-error-snack-bar',
            });
          },
        }),
      );
  }

  updateNewsletterConfig(
    data: UpdateAccountNewsletterConfigData,
  ): Observable<void> {
    return this.http
      .post<void>(`${environment.apiUrl}/user/profile`, {
        newsletter_config: {
          topics_selected: Object.fromEntries(
            data.newsCategoryIds.map((id) => [id, id.toString()]),
          ),
          days: Object.fromEntries(
            data.days.map((day) => [day, day.toString()]),
          ),
          hour: data.hour,
        },
      })
      .pipe(tap(() => this.updatedSubject.next()));
  }
}
